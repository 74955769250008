import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Modal } from '@material-ui/core';
import { navigate } from 'gatsby';
import { MdClear } from 'react-icons/md';

const NbVisitorAlert = ({ t, visible, dispatch }) => {
  const onClose = () => {
    dispatch({ limitVisitors: false });
    navigate('/reservation/');
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={visible}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="modal small"
    >
      <div className="modal-content">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ position: 'absolute', right: 10, top: 10 }}>
            <IconButton onClick={onClose}>
              <MdClear />
            </IconButton>
          </div>
        </div>

        <p>{t('reservationPage.totalLimit')}</p>
      </div>
    </Modal>
  );
};

export default NbVisitorAlert;

NbVisitorAlert.propTypes = {
  t: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
};

NbVisitorAlert.defaultProps = {
  visible: false
};
