import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withTrans } from '../i18n/withTrans';
import { navigate } from 'gatsby';
import CookiesModal from '../components/Layout/CookiesModal';
import AvailableTicketsModal from '../components/Layout/AvailableTicketsModal';
import Layout from '../components/Layout/Layout';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import FiltersCard from '../components/ReservationPage/FiltersCard';
import Favicon from '../assets/images/favicon-manatour-16x16.png';
import PopUpErrors from '../components/ReservationPage/PopUpErrors';

const isBrowser = typeof window !== 'undefined';

function reducer(state, action) {
  return { ...state, ...action };
}

const ReservationPage = ({ t }) => {
  const initialState = {
    full_price: 0,
    reduced_price: 0,
    free_price: 0,
    child_price: 0,
    activeStep: 0,
    totalVisitors: 0,
    showVisibleInfos: true,
    cookies_1: true,
    cookies_2: false
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openCookies, setOpenCookies] = useState(false);
  const [openTicketsWarning, setOpenTicketsWarning] = useState(false);
  const steps = ['visitSelection', 'visitorsInfos', 'commandRecap', 'payment'];

  let isCookiesAccepted = false;
  if (isBrowser) {
    isCookiesAccepted = JSON.parse(localStorage.getItem('isCookiesAccepted'));
  }

  useEffect(() => {
    localStorage.setItem('totalVisitors', 0);
    let availableTicketsModal = false;
    if (isBrowser) {
      availableTicketsModal = JSON.parse(
        localStorage.getItem('warning_av_tickets')
      );
    }
    if (availableTicketsModal) {
      setOpenTicketsWarning(true);
    }
    if (!isCookiesAccepted) {
      setOpenCookies(!openCookies);
    }
  }, [isCookiesAccepted]);

  const onValidateFilters = () => {
    const listErrors = [];

    const totalVisitors =
      state.free_price +
      state.full_price +
      state.reduced_price +
      state.child_price;

    localStorage.setItem('totalVisitors', totalVisitors);

    if (!state.language) {
      listErrors.push('language');
      dispatch({ incomplete_language: true });
    }

    if (!state.visitType) {
      listErrors.push('visitType');
      dispatch({ incomplete_visit_type: true });
    }

    if (!state.totalVisitor) {
      listErrors.push('visitorsNb');
      dispatch({ incomplete_visitor_nb: true });
    }

    if (listErrors.length >= 2) {
      listErrors.push('fieldsIncomplete');
      dispatch({ incomplete_fields: true });
    }

    if (
      state.free_price &&
      !state.full_price &&
      !state.reduced_price &&
      !state.child_price
    ) {
      listErrors.push('free_price');
      dispatch({ only_free_price: true });
    }

    if (
      state.child_price &&
      !state.full_price &&
      !state.reduced_price &&
      !state.free_price
    ) {
      listErrors.push('free_price');
      dispatch({ only_child_price: true });
    }
    dispatch({ errors: listErrors });

    if (!listErrors.length) {
      localStorage.setItem('full_price', state.full_price);
      localStorage.setItem(
        'reduced_price',
        state.visitType === '17' ? '0' : state.reduced_price
      );
      localStorage.setItem(
        'child_price',
        state.visitType === '17' ? '0' : state.child_price
      );
      localStorage.setItem(
        'free_price',
        state.visitType === '17' ? '0' : state.free_price
      );
      localStorage.setItem('language', state.language);
      localStorage.setItem('visitType', state.visitType);
      navigate('/ma-visite/');
    }
  };

  const addNbVisitors = (type) => {
    switch (type) {
      case 'full_price':
        dispatch({
          full_price: state.full_price + 1,
          totalVisitor: (state.totalVisitor || 0) + 1
        });
        break;
      case 'reduced_price':
        dispatch({
          reduced_price: state.reduced_price + 1,
          totalVisitor: (state.totalVisitor || 0) + 1
        });
        break;
      case 'child_price':
        dispatch({
          child_price: state.child_price + 1,
          totalVisitor: (state.totalVisitor || 0) + 1
        });
        break;
      case 'free_price':
        dispatch({
          free_price: state.free_price + 1,
          totalVisitor: (state.totalVisitor || 0) + 1
        });
        break;
      default:
        dispatch();
        break;
    }
  };

  const subtractNbVisitors = (type) => {
    switch (type) {
      case 'full_price':
        dispatch({
          full_price: state.full_price - 1,
          totalVisitor: state.totalVisitor - 1
        });
        break;
      case 'reduced_price':
        dispatch({
          reduced_price: state.reduced_price - 1,
          totalVisitor: state.totalVisitor - 1
        });
        break;
      case 'child_price':
        dispatch({
          child_price: state.child_price - 1,
          totalVisitor: state.totalVisitor - 1
        });
        break;
      case 'free_price':
        dispatch({
          free_price: state.free_price - 1,
          totalVisitor: state.totalVisitor - 1
        });
        break;
      default:
        dispatch();
        break;
    }
  };

  const onCancel = () => {
    navigate('/');
  };

  const onHandleLanguageChange = (value) => {
    dispatch({ language: value });
  };

  const onHandleVisitTypeChange = (value) => {
    dispatch({ showVisibleInfos: true, visitType: value });
  };

  const handleClose = () => {
    dispatch({
      only_free_price: false,
      incomplete_fields: false,
      only_child_price: false,
      incomplete_language: false,
      incomplete_visit_type: false,
      incomplete_visitor_nb: false
    });
  };

  return (
    <Layout pageName="reservation_page">
      <Helmet>
        <title>{t('helmet.reservation_page')}</title>
        <link
          rel="icon"
          type="image/png"
          href={Favicon}
          sizes="16x16"
          className="favicon"
        />
      </Helmet>
      <CookiesModal
        open={openCookies}
        setOpen={setOpenCookies}
        state={state}
        dispatch={dispatch}
      />
      <AvailableTicketsModal
        open={openTicketsWarning}
        setOpenTicketsWarning={setOpenTicketsWarning}
      />
      <PopUpErrors
        open={state.incomplete_language}
        handleClose={handleClose}
        text="language"
      />
      <PopUpErrors
        open={state.incomplete_visit_type}
        handleClose={handleClose}
        text="visit_type"
      />
      <PopUpErrors
        open={state.incomplete_visitor_nb}
        handleClose={handleClose}
        text="visitor_nb"
      />
      <PopUpErrors
        open={state.incomplete_fields}
        handleClose={handleClose}
        text="fieldsIncomplete"
      />
      <PopUpErrors
        open={state.only_child_price}
        handleClose={handleClose}
        text="only_child_price"
      />
      <PopUpErrors
        open={state.only_free_price}
        handleClose={handleClose}
        text="only_free_price"
      />
      <div className="stepper-wrapper">
        <Stepper alternativeLabel activeStep={state.activeStep}>
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel>{t(`steps.${step}`)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="step1-title">
        <h2>
          {t('landingPage.title2')} {t('landingPage.title3')}
        </h2>
        <h1>{t('reservationPage.title')}</h1>
      </div>
      <FiltersCard
        t={t}
        state={state}
        dispatch={dispatch}
        addNbVisitors={addNbVisitors}
        subtractNbVisitors={subtractNbVisitors}
        onHandleLanguageChange={onHandleLanguageChange}
        onHandleVisitTypeChange={onHandleVisitTypeChange}
        onValidateFilters={onValidateFilters}
        onCancel={onCancel}
      />
    </Layout>
  );
};

export default withTrans(ReservationPage);

ReservationPage.propTypes = {
  t: PropTypes.func.isRequired
};

FiltersCard.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.shape({
    full_price: PropTypes.number,
    reduced_price: PropTypes.number,
    free_price: PropTypes.number,
    child_price: PropTypes.number,
    activeStep: PropTypes.number,
    showVisibleInfos: PropTypes.bool
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  addNbVisitors: PropTypes.func.isRequired,
  subtractNbVisitors: PropTypes.func.isRequired,
  onHandleLanguageChange: PropTypes.func.isRequired,
  onHandleVisitTypeChange: PropTypes.func.isRequired,
  onValidateFilters: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

PopUpErrors.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

PopUpErrors.defaultProps = {
  open: undefined
};
