import React from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../../i18n/withTrans';
import { IconButton, Modal } from '@material-ui/core';
import { MdClear } from 'react-icons/md';

const PricesInfosModal = ({ t, state, dispatch }) => {
  const onClose = () => {
    dispatch({ pricesModal: false });
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={state.pricesModal}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="modal small"
    >
      <div className="modal-content">
        <h2 className="modal-title">{t('reservationPage.pricesInfos')}</h2>
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>
        <h3>{t('reservationPage.nbPersons.reduced_price')}</h3>
        <p>{t('reservationPage.nbPersons.reduced_price_details')}</p>
        <h3>{t('reservationPage.nbPersons.child_price')}</h3>
        <p>{t('reservationPage.nbPersons.child_price_details')}</p>
        <h3>{t('reservationPage.nbPersons.free_price')}</h3>
        <p>{t('reservationPage.nbPersons.free_price_details')}</p>
      </div>
    </Modal>
  );
};

export default withTrans(PricesInfosModal);

PricesInfosModal.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.shape({
    pricesModal: PropTypes.bool
  }).isRequired,
  dispatch: PropTypes.func.isRequired
};

PricesInfosModal.defaultProps = {
  state: PropTypes.shape({
    pricesModal: undefined
  })
};
