import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Divider,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withTrans } from '../../i18n/withTrans';

const CookiesModal = ({ t, open, setOpen, state, dispatch }) => {
  const onClose = () => {
    setOpen(!open);
    localStorage.setItem('isCookiesAccepted', 'true');
  };

  const language =
    typeof window !== 'undefined'
      ? localStorage.getItem('language_site')
      : 'fr';

  const navigatorsHelper = [
    {
      title: 'Google Chrome',
      link: `https://support.google.com/chrome/answer/95647?hl=${language}`
    },
    {
      title: 'Mozilla Firefox',
      link: 'https://support.mozilla.org/fr/kb/effacer-cookies-donnees-site-firefox?redirectslug=effacer-les-cookies-pour-supprimer-les-information&redirectlocale=fr'
    },
    {
      title: 'Safari',
      link: 'https://support.apple.com/fr-fr/105082'
    },
    {
      title: 'Microsoft Edge',
      link: 'https://support.microsoft.com/fr-fr/windows/g%C3%A9rer-les-cookies-dans-microsoft-edge-afficher-autoriser-bloquer-supprimer-et-utiliser-168dab11-0753-043d-7c16-ede5947fc64d'
    }
  ];

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#ccc',
      '&$checked': {
        color: '#4CAF50'
      },
      '&$checked + $track': {
        backgroundColor: '#4CAF50'
      },
      '& + $track': {
        backgroundColor: '#b0b0b0'
      }
    },
    checked: {},
    track: {}
  })(Switch);

  return (
    <Modal
      open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      disableBackdropClick
      className="modal cookies-modal"
    >
      <div className="modal-content">
        <h2 className="modal-title">{t('cookies.title')}</h2>
        <p>
          <i>{t('cookies.last_update')}</i>
        </p>
        <p>{t('cookies.description')}</p>
        <Divider />
        <h3>{t('cookies.def_1_title')}</h3>
        <p>{t('cookies.def_1')}</p>
        <Divider />
        <h3>{t('cookies.def_2_title')}</h3>
        <p>{t('cookies.def_2_description')}</p>
        <ul style={{ listStyle: 'none', padding: 0 }}>
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <p style={{ margin: 0, whiteSpace: 'nowrap', minWidth: 275 }}>
              {t('cookies.def_2_1')}
            </p>
            <FormControlLabel
              control={
                <CustomSwitch
                  disabled
                  checked={state.cookies_1}
                  onChange={() => dispatch({ cookies_1: !state.cookies_1 })}
                />
              }
              label={state.cookies_1 ? 'Oui' : 'Non'}
              style={{
                marginLeft: 8,
                fontWeight: 'bold',
                color: state.cookies_1 ? '#4CAF50' : '#666'
              }}
            />
            <p>{t('cookies.def_2_1_descr')}</p>
          </li>
          <li
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <p style={{ margin: 0, whiteSpace: 'nowrap', minWidth: 275 }}>
              {t('cookies.def_2_2')}
            </p>
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={state.cookies_2}
                  onChange={() => dispatch({ cookies_2: !state.cookies_2 })}
                />
              }
              label={state.cookies_2 ? 'Oui' : 'Non'}
              style={{
                marginLeft: 8,
                fontWeight: 'bold',
                color: state.cookies_2 ? '#4CAF50' : '#666'
              }}
            />
            <p>{t('cookies.def_2_2_descr')}</p>
          </li>
        </ul>
        <Divider />
        <h3>{t('cookies.def_3_title')}</h3>
        <p>{t('cookies.def_3_description')}</p>
        <p>{t('cookies.def_3_description_2')}</p>
        <ul>
          {navigatorsHelper?.map((navigator) => (
            <li key={navigator.title}>
              <p>
                {`${navigator.title}: `}
                <a
                  href={navigator.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  lien vers l&apos;aide
                </a>
              </p>
            </li>
          ))}
        </ul>
        <Divider />
        <h3>{t('cookies.def_4_title')}</h3>
        <p>{t('cookies.def_4_description')}</p>
        <Divider />
        <h3>{t('cookies.def_5_title')}</h3>
        <p>{t('cookies.def_5_description_1')}</p>
        <p>{t('cookies.def_5_description_2')}</p>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: 16 }}>
          <Button
            onClick={() => onClose()}
            style={{
              background: '#000',
              color: 'white',
              borderRadius: 0,
              marginLeft: 8
            }}
            variant="contained"
          >
            {t('cookies.accept')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(CookiesModal);

CookiesModal.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  state: PropTypes.shape({
    cookies_1: PropTypes.bool,
    cookies_2: PropTypes.bool,
    newsletter: PropTypes.bool,
    analytics: PropTypes.bool
  }).isRequired,
  dispatch: PropTypes.func.isRequired
};

CookiesModal.defaultProps = {
  open: false
};
