import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Divider } from '@material-ui/core';
import { withTrans } from '../../i18n/withTrans';

const AvailableTicketsModal = ({ t, open, setOpenTicketsWarning }) => {
  const onCloseWarningTicketModal = () => {
    setOpenTicketsWarning(false);
    localStorage.setItem('warning_av_tickets', 'false');
  };

  return (
    <Modal
      open={open}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="modal cookies-modal"
    >
      <div
        className="modal-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          padding: '20px',
          width: '100%',
          maxWidth: '600px',
          background: 'white',
          borderRadius: '8px',
          margin: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute'
        }}
      >
        <Divider style={{ width: '100%', marginBottom: '10px' }} />
        <h2 className="modal-title" style={{ marginBottom: '8px' }}>
          {t('warning_modal.content')}
        </h2>
        <p className="modal-description" style={{ marginBottom: '20px' }}>
          {t('warning_modal.content_2')}
        </p>
        <Divider style={{ width: '100%', marginBottom: '10px' }} />
        <Button
          onClick={onCloseWarningTicketModal}
          style={{
            background: '#000',
            color: 'white',
            borderRadius: '4px',
            fontSize: '14px',
            padding: '6px 12px',
            minWidth: '100px',
            textTransform: 'none',
            marginTop: '10px'
          }}
          variant="contained"
        >
          {t('warning_modal.accept')}
        </Button>
      </div>
    </Modal>
  );
};

export default withTrans(AvailableTicketsModal);

AvailableTicketsModal.propTypes = {
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpenTicketsWarning: PropTypes.func.isRequired
};

AvailableTicketsModal.defaultProps = {
  open: false
};
